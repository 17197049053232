import { graphql, Link, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import Logo from "../../assets/images/kieferle-partners.inline.svg"
import { MenuContext } from "../../context/MenuContext"
import { createLocalLink } from "../../utils"
import SocialMenu from "./social"

const MENU_QUERY = graphql`
  fragment MenuFields on WpMenuItem {
    id
    databaseId
    parentId
    label
    url
    cssClasses
    target
  }

  query GET_MENU_ITEMS {
    wpMenu(slug: { eq: "primary" }) {
      name
      menuItems {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
              childItems {
                nodes {
                  ...MenuFields
                  childItems {
                    nodes {
                      ...MenuFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const RenderMenuItem = props => {
  const { menuItem, location, subMenu } = props
  const link = createLocalLink(menuItem.url)
  const path = location && location !== undefined ? location.pathname : ""
  let classes = menuClasses(menuItem, path, link)
  const [activeLink, setActiveLink] = useState("")
  // const [childItems, setChildItems] = useState([])
  const handleClick = e => {
    if (e.currentTarget.classList.contains("active")) {
      setActiveLink("")
    } else {
      setActiveLink(e.currentTarget.dataset.name)
    }
  }

  if (menuItem.parentId && !subMenu) {
    return null
  }

  if (menuItem.cssClasses) {
    menuItem.cssClasses.map(q => classes.push(q))
  }

  return (
    <li className={classes.join(" ")} key={menuItem.id}>
      {link && (link.includes("#") || !link.includes(process.env.WORDPRESS)) ? (
        <a
          className={`nav-link ${
            activeLink === menuItem.label ? "active" : null
          }`}
          onClick={e => handleClick(e)}
          href={link}
          target={menuItem.target ? menuItem.target : "_self"}
          data-name={menuItem.label}
          rel="noopener noreferrer"
        >
          {parse(menuItem.label)}
        </a>
      ) : (
        <Link className="nav-link" to={link} activeClassName="active">
          {parse(menuItem.label)}
        </Link>
      )}
      {renderSubMenu(menuItem, location)}
    </li>
  )
}

const renderSubMenu = (menuItem, location) => {
  if (menuItem.childItems && menuItem.childItems.nodes.length) {
    return (
      <ul className="dropdown-menu">
        {menuItem.childItems.nodes.map((item, index) => {
          // console.log(item);
          return (
            <RenderMenuItem
              key={index}
              subMenu="true"
              menuItem={item}
              location={location}
            />
          )
        })}
      </ul>
    )
  }
}

const menuClasses = (menuItem, path, link) => {
  let classes = ["menu-item"]
  classes.push("menu-item-" + menuItem.databaseId)
  if (menuItem.childItems && menuItem.childItems.nodes.length) {
    classes.push("dropdown")
    classes.push("dropdown-toggle")
    classes.push("menu-item-has-children")
  }
  if (link === path) {
    classes.push("active")
  }
  return classes
}

class SiteMenu extends React.Component {
  static contextType = MenuContext

  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.state = {
      isActive: false,
      activeLink: "",
    }
  }

  handleClick = () => {
    this.setState({
      isActive: !this.state.isActive,
    })
  }

  render() {
    const { toggleMenu } = this.context
    // console.log(isMenuOpen)

    return (
      <StaticQuery
        query={MENU_QUERY}
        render={data => {
          if (data?.wpMenu?.menuItems) {
            return (
              <>
                <nav
                  className={`navbar ${this.state.isActive ? "expanded" : ""}`}
                >
                  <Link className="navbar-brand text-hide" to="/">
                    Kieferle & Partner
                    <Logo id="logo" alt="Kieferle & Partner" />
                  </Link>
                  <div
                    className={`collapse navbar-collapse ${
                      this.state.isActive ? "show" : ""
                    }`}
                    id="topNav"
                  >
                    <button
                      className={`navbar-toggler ${
                        this.state.isActive ? "nav-open" : ""
                      }`}
                      type="button"
                      data-toggle="collapse"
                      onClick={() => {
                        this.handleClick()
                        toggleMenu()
                      }}
                      aria-label="menu"
                    >
                      <span className="icon-bar bar1"></span>
                      <span className="icon-bar bar2"></span>
                      <span className="icon-bar bar3"></span>
                    </button>
                    <ul className="navbar-nav">
                      {data.wpMenu.menuItems.nodes.map((menuItem, index) => {
                        return (
                          <RenderMenuItem
                            key={index}
                            menuItem={menuItem}
                            location={this.props.location}
                          />
                        )
                      })}
                    </ul>
                    <SocialMenu location={this.props.location} />
                  </div>
                </nav>
              </>
            )
          } else {
            return null
          }
        }}
      />
    )
  }
}

export default SiteMenu
