import React, { Component, createContext } from "react"

export const MenuContext = createContext()

class MenuContextProvider extends Component {
  state = {
    isMenuOpen: false,
  }
  toggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
  }
  render() {
    // console.log(this.state.isMenuOpen)
    return (
      <MenuContext.Provider
        value={{ ...this.state, toggleMenu: this.toggleMenu }}
      >
        {this.props.children}
      </MenuContext.Provider>
    )
  }
}

export default MenuContextProvider
