import React from "react"
// const renderLoader = () => <p>Loading...</p>;
// const SiteMenu = lazy(() => import("../Menu"));
// import { ThemeContext } from "../../context/ThemeContext"
import SiteMenu from "../Menu"

class Header extends React.Component {
  // static contextType = ThemeContext

  componentDidMount() {
    if (typeof window !== `undefined`) {
      let el = document.getElementsByTagName("html")[0]
      el.classList.remove("projects")
    }
  }

  render() {
    // const { toggleTheme } = this.context
    // console.log(toggleTheme)
    return (
      <header>
        <SiteMenu location={this.props.location} />
      </header>
    )
  }
}

export default Header
